import React from "react"
import { graphql } from "gatsby"

const Laboratory = ({ data }) => {
  return <div>{data.datoCmsCancerLaboratory.title}</div>
}

export default Laboratory

export const query = graphql`
  query laboratoryPageQuery($slug: String!) {
    datoCmsCancerLaboratory(slug: { eq: $slug }) {
      id
      content
      title
      slug
      meta {
        status
      }
    }
  }
`
